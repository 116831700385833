import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColDef, GridOptions } from "@ag-grid-community/core";
import React from "react";

export const RowNumberColumn = (selectable = false, headerName = "№"): ColDef => ({
  headerName,
  valueGetter: "node.rowIndex + 1",
  width: selectable ? 50 : 50,
  sortable: false,
  resizable: false,
  cellClass: "righted row-number",
  checkboxSelection: selectable,
});

export const FIGMA_TABLE_ICON_SIZE = 10;

export const TableIcon = (name: string, size = 14, asString = true) =>
  asString ? (
    `<i class="fa ${name}" aria-hidden="true" style="font-size:${size}px; width:${size}px; height:${size}px; color: #182026"></i>`
  ) : (
    <i
      className={`fa ${name}`}
      aria-hidden="true"
      style={{
        fontSize: `${size}px`,
        width: `${size}px`,
        height: `${size}px`,
        color: "#182026",
      }}
    />
  );

export const DefaultAgGridOptions = (figma = false): GridOptions => ({
  icons: {
    sortAscending: TableIcon("fa-caret-up", figma ? FIGMA_TABLE_ICON_SIZE : 14),
    sortDescending: TableIcon("fa-caret-down", figma ? FIGMA_TABLE_ICON_SIZE : 14),
    checkboxChecked: '<GeneralIcon type="general-checkbox" />',
    checkboxUnchecked: '<GeneralIcon type="general-zero-checkbox" />',
  },
  sortingOrder: ["desc", "asc"],
  animateRows: true,
});

export const OrderTableAgGridOptions = (figma = false): GridOptions => ({
  icons: {
    sortAscending: TableIcon("fa-caret-up", figma ? FIGMA_TABLE_ICON_SIZE : 14),
    sortDescending: TableIcon("fa-caret-down", figma ? FIGMA_TABLE_ICON_SIZE : 14),
    checkboxChecked: '<GeneralIcon type="general-checkbox" />',
    checkboxUnchecked: '<GeneralIcon type="general-zero-checkbox" />',
  },
  sortingOrder: ["desc", "asc"],
  treeData: true,
  groupDefaultExpanded: 1,
  getDataPath: (data) => data.path,
  animateRows: true,
  autoGroupColumnDef: {
    headerName: "Наименование",
    field: "name",
    minWidth: 100,
    cellRenderer: "agGroupCellRenderer",
    sortable: true,
    filter: "agTextColumnFilter",
    filterParams: {
      treeList: true,
      keyCreator: (params: any) => (params.value ? params.value.join("#") : null),
    },
    cellRendererParams: {
      checkbox: false,
      suppressCount: true,
    },
  },
  // postSort: (nodes: RowNode[]) => {
  //   let rowNodes = nodes;
  //   // here we put Ireland rows on top while preserving the sort order
  //   let nextInsertPos = 0;
  //   for (let i = 0; i < rowNodes.length; i++) {
  //     const data = rowNodes[i].data;
  //     // console.info(rowNodes[i]);
  //     if (data.pathName) {
  //       if (rowNodes[i] && rowNodes[i].childrenAfterGroup) {
  //         const childItem = rowNodes[i].childrenAfterGroup;
  //         // console.info(childItem ? childItem[0] : 0);
  //       }
  //       rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
  //       nextInsertPos++;
  //     }
  //   }
  // },
  rowDragManaged: false,
});

export const defaultModules = [ClientSideRowModelModule];

export const ROW_HEIGHT = 20;
export const HEADER_HEIGHT = 29;

export const FIGMA_ROW_HEIGHT = 22;
export const FIGMA_HEADER_HEIGHT = 26;
